import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const AlphabetSelector = ({ alphabetCount, title, subtitle }) => {
  return (
    <>
      <h2 className={"display-4"}>
        { title || 'Alphabet-Selektor' }

        {
          subtitle &&
          <p>{subtitle}</p>
        }
      </h2>
      <div className="AlphabetNav">
        {
          Object.keys(alphabetCount).map((key) => (
            <div key={key}>
              <AnchorLink to={"#L_" + key} title={key}>
                {key}
              </AnchorLink>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default AlphabetSelector
